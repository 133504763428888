<template>
  <div class="search">
    <div class="layout">
      <div>
        <div class="nav">
          <div class="nav-left">
            <div class="sousuo">搜索</div>
            <input type="text" placeholder="请输入搜索内容" v-model="searchValue" />
            <div class="searchBtn" @click="handleSearch">
              <img src="../assets/image/search.2f2ec47.png" alt="" />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="tip">
            搜索 “<span>{{ searchValue }}</span
            >” 的结果
          </div>
          <div>
            <div class="list" v-for="(item, index) in searchList" :key="index">
              <a v-if="item.url && item.url !== ''" :href="item.url">
                <img
                  v-if="item.thumb1 && item.thumb1 != ''"
                  :src="item.thumb1"
                  class="img"
                  style="width: 148px;height: 100px; float: left;"
                />
                <img
                  v-else
                  src="../assets/image/search.png"
                  alt=""
                  class="img"
                  style="width: 148px;height: 100px; float: left;"
                />
                <div class="main" style="width: 983px; height: 130px; float: right; ">
                  <span class="my-highlight" style="color: rgb(37, 80, 107);" v-html="item.title"> </span>
                  <span class="my-highlight1" style="color: rgb(37, 80, 107);" v-html="item.content"> </span>
                  <div class="time">{{ dayjs(item.publishDate).format('MM-DD') }}</div>
                </div>
                <div style="clear: both"></div>
              </a>
              <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                <img
                  v-if="item.thumb1 && item.thumb1 != ''"
                  :src="item.thumb1"
                  class="img"
                  style="width: 148px;height: 100px; float: left;"
                />
                <img
                  v-else
                  src="../assets/image/search.png"
                  alt=""
                  class="img"
                  style="width: 148px;height: 100px; float: left;"
                />
                <div class="main" style="width: 983px; height: 130px; float: right; ">
                  <span class="my-highlight" style="color: rgb(37, 80, 107);" v-html="item.title"> </span>
                  <span class="my-highlight1" style="color: rgb(37, 80, 107);" v-html="item.content"> </span>
                  <div class="time">{{ dayjs(item.publishDate).format('MM-DD') }}</div>
                </div>
                <div style="clear: both"></div>
              </a>
            </div>
          </div>
        </div>
        <div style="margin: 30px 0;float: left;">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div style="clear:left"></div>
  </div>
</template>

<script>
import { articleSearch } from '@/api/common'
export default {
  data() {
    return {
      searchList: [],
      pageSize: 5,
      pageNum: 1,
      total: 0,
      searchValue: '',
      applicationUuid: '',
      lang: 'zh-CN'
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 50) {
        return value.slice(0, 50) + '...'
      }
      return value
    }
  },
  computed: {},
  mounted() {
    var _this = this
    _this.searchValue = _this.$route.params.title

    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }

    _this.articles()
  },
  methods: {
    // 获取列表
    articles() {
      var _this = this
      let data = {
        title: _this.$route.params.title,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize,
        entTApplicationUuid: this.applicationUuid
      }
      articleSearch(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.searchList = data.rows
          _this.total = data.totalRows
          if (_this.searchValue != ' ') {
            for (let i in _this.searchList) {
              //高亮替换
              _this.searchList[i].title = this.showData(_this.searchList[i].title)
              // let regx = /<\/?[a-zA-Z]+(\s+[a-zA-Z]+=".*")*>/g  只取文本，但是会有会有文本标签和样式
              // _this.searchList[i].content = _this.searchList[i].content.replaceAll(regx, '')
              _this.searchList[i].content = _this.searchList[i].content.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')
              _this.searchList[i].content = this.showData(_this.searchList[i].content)
            }
          }
        }
      })
    },
    // 筛选变色 如果搜索结果记录包含关键字，那么修改样式
    showData(val) {
      if (this.checkParam(val, this.searchValue)) {
        //以 关键词 去分隔字符串 形成一个数组
        let arrry = val.split(this.searchValue)

        //把数组元素用固定分隔符（此处为已添加高亮的关键词）拼接为一个完整字符串
        val = arrry.join('<span style="color: #901211;font-weight: 700;">' + this.searchValue + '</span>')

        return val
      } else {
        return val //不做任何修改
      }
    },
    //判断搜索记录是否包含某个关键字
    checkParam(val, param) {
      // 判断在标题中是否存在关键字，如果要检索的字符串值没有出现，则该方法返回 -1。
      if (val.indexOf(param) !== -1 && param !== '') {
        return true
      } else {
        return false
      }
    },
    currentChange(e) {
      var _this = this
      _this.pageNum = e
      _this.articles()
    },
    handleSearch() {
      var _this = this
      if (!_this.searchValue || _this.searchValue == '') {
        this.$message({
          message: '请输入关键词',
          type: 'warning'
        })
      } else {
        window.location.href = `/search/${_this.searchValue}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.heightLight {
  color: #ff0000;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.search .nav {
  display: flex;
  justify-content: center;
}
.search .nav-left {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
}
.search .sousuo,
input {
  height: 50px;
  background: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
}
.search input {
  outline: none;
  padding-left: 10px;
  width: 400px;
  border: none;
}
.search .sousuo,
input {
  height: 50px;
  background: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
}
.search button,
input {
  overflow: visible;
}
.search button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.search .searchBtn {
  width: 110px;
  height: 50px;
  background: #396ea6;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.search img {
  width: 40px;
  height: 40px;
}
.search .content,
.tip {
  box-sizing: border-box;
}
.search .content {
  margin-top: 1px;
  background-color: #fff;
  min-height: 100px;
  padding: 25px;
  font-size: 12px;
}
.search .tip {
  width: 1150px;
  height: 36px;
  background: #fef5d5;
  border: 1px solid #e3ceac;
  line-height: 36px;
  padding-left: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #888;
}
.search .list {
  display: flex;
  align-items: center;
  padding: 24px 0 25px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  margin-top: 16px;
}
.search .img {
  width: 148px;
  height: 100px;
  background-color: #ccc;
  margin-right: 19px;
}
.search .time {
  font-size: 14px;
  font-weight: 400;
  color: #888;
  margin-top: 18px;
}
.search .time,
.title {
  font-family: Microsoft YaHei;
}
.search .sousuo,
input {
  height: 50px;
  background: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
}
.search .sousuo {
  width: 100px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-highlight1 {
  font-size: 12px !important;
  font-family: '微软雅黑', '宋体' !important;
  margin-top: 10px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.my-highlight1 p,
span,
font {
  font-size: 12px !important;
  font-family: '微软雅黑', '宋体' !important;
}
</style>
